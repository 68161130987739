var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loadingClass" },
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "", "text-center": "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                [
                  _c("v-progress-circular", {
                    attrs: {
                      indeterminate: "",
                      color: "secondary",
                      size: "64",
                      width: "6"
                    }
                  }),
                  _c("div", { staticClass: "mt-3" }, [
                    _vm._v(" Logging in... ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }