<template>
    <div class="loadingClass">
        <v-container fill-height text-center>
            <v-row align="center">
                <v-col>
                    <v-progress-circular indeterminate color="secondary" size="64" width="6"></v-progress-circular>
                    <div class="mt-3">
                        Logging in...
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    methods: {},
    created() {
        this.$auth.handleAuthentication()
        this.$router.push({ name: 'login' })
    }
}
</script>
